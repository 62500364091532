<template>
    <v-card class="elevation-0" color="secondary" dark>
        <v-card-text class="px-3 px-md-8 pb-5 d-flex align-center justify-center">
            <div style="width: 100%;">
                
                <h1 class="title white--text text-center mt-3 mt-md-4 mb-1 mx-auto" style="max-width: 680px;">
                    <v-icon x-large color="primary" class="mb-2">fa fa-user-lock</v-icon>
                    <br>
                    View Emergency Information
                </h1>

                <v-alert dense type="error" v-if="errors" class="body-2 lh-15 mt-2 mx-auto" style="max-width: 600px;">
                    {{ errors }}
                </v-alert>

                <v-alert dense type="success" v-if="codeSent" outlined class="subtitle-1 lh-15 font-weight-medium mt-2 mx-auto" style="max-width: 600px;">
                    Check your phone for your new code!
                </v-alert>

                <template v-if="preview && !previewAuth">

                    <p class="subtitle-1 mb-2 lh-13 text-center white--text mx-auto" style="max-width: 650px;">
                        If this were a live alert, your emergency contacts would be asked to provide a unique access code that we sent to their phones.
                    </p>
                    <p class="subtitle-1 mb-4 lh-13 text-center white--text mx-auto" style="max-width: 650px;">
                        To protect your information, a new access code is required for each view.
                    </p>

                        <v-btn color="primary" v-if="preview" @click.native="$emit('authd', user)" rounded class="d-block mt-5 mx-auto">Skip for Preview</v-btn>
                        <p class="body-2 text-center mt-3">- or -</p>
                        <v-btn color="primary" v-if="preview" @click.native="previewAuth=true" rounded class="d-block mt-3 mx-auto">Use SMS Code to Access</v-btn>
                    
                    <p class="caption mt-5 px-md-10 mb-0 text-center secondary--text">The information contained herein may be sensitive. <br> For privacy and security, only the emergency contacts {{ firstName }} has listed can access this data.</p>

                </template>
                <template v-else>

                    <p class="subtitle-1 mb-5 text-center white--text" v-if="contact.first_view || codeSent">
                        Please enter the 5-digit code we just sent to: {{ contact.phone }}
                    </p>
                    <p class="subtitle-1 mb-4 lh-13 text-center white--text mx-auto" style="max-width: 650px;" v-else>
                        <template v-if="!requesting">
                            One-time access code has expired. If you are one of the emergency contacts {{ firstName }} has listed, you can request a new code to access this information.
                        </template>
                        <template v-else>
                            Provide the last 4 digits of the phone number you received this alert on:
                        </template>
                    </p>

                    <CodeInput :size="5" :key="acKey" v-if="canSubmit" @updated="handleAccessCodeInput($event)" />
                    <CodeInput :size="4" v-if="requesting && !canSubmit" :defaultCode="(preview) ? last4 : null" @updated="handlePhoneCodeInput($event)" />

                    <v-btn color="primary" :loading="authing" :disabled="accessCode.length!=5" @click.native="authorize()" v-if="canSubmit" class="d-block mt-5 mx-auto">Unlock Private Info</v-btn>
                    
                    <v-btn color="primary" v-if="!canSubmit && !requesting" class="d-block mt-3 mx-auto" @click.native="requesting=true">Request a New Code</v-btn>
                    <!-- <v-btn color="primary" v-if="preview" @click.native="$emit('authd', user)" outlined class="d-block mt-3 mx-auto">Skip for Preview</v-btn> -->
                    <v-btn color="primary" :loading="authing" v-if="requesting && !canSubmit" :disabled="last4.length!=4" class="d-block mt-7 mx-auto" @click.native="getNewCode()">Request Access Code</v-btn>
                    <v-btn v-if="requesting" text class="d-block mt-3 mx-auto" @click.native="requesting=false">Cancel</v-btn>
                    
                    <p class="caption mt-5 px-md-10 mb-0 text-center secondary--text">The information contained herein may be sensitive. <br> For privacy and security, only the emergency contacts {{ firstName }} has listed can access this data.</p>

                </template>
                
            </div>
        </v-card-text>
        <Confirm v-if="redirectTo" title="Redirecting..." subtitle="Your last-4 digits were confirmed, but we need to redirect you to the correct URL for this alert." msg="The link may have been typed or pasted incorrectly." confirmBtnText="Continue" @confirmed="handleRedirect()" @closed="handleRedirect()" />
    </v-card>
</template>
<script>
    
export default {
    name: 'AlertAuth',
    props: {
        authType: {
            required: false,
            type: String,
            default: 'notes'
        },
        firstName: {
            required: true,
            type: String
        },
        contact: {
            required: false,
            type: Object
        },
        user: {
            required: false,
            type: Object
        },
        preview: {
            required: false,
            type: Boolean
        }
    },
    components: {
        CodeInput: () => import('@/components/common/CodeInput'),
        Confirm: () => import('@/components/common/Confirm'),
    },
    computed: {
        canSubmit(){
            return this.contact.first_view || this.codeSent;
        }
    },
    data: () => ({
        acKey: 0,
        accessCode: '',
        codeSent: false,
        errors: null,
        last4: '',
        previewAuth: false,
        redirectTo: null,
        requesting: true,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        authing: false,
    }),
    methods: {
        initData(){
            if(this.preview && this.user.phone){
                this.last4 = this.user.phone.substr(10,4);
            }
        },
        handlePhoneCodeInput(val){
            this.last4 = val;
            if(this.last4.length==4 && !this.preview){
                this.getNewCode();
            }
        },
        handleAccessCodeInput(val){
            this.accessCode = val;
            if(this.accessCode.length==5){
                this.authorize();
            }
        },
        authorize(){ 
            this.authing = true;
            this.errors = null;
            let request = {
                endpoint: '/alerts/auth/' + this.$route.params.id, 
                method: 'post',
                data: {
                    code: this.accessCode,
                    preview: this.user
                },
            };
            this.$appFn.callApi(request).then((res)=>{
                this.codeSent = false;
                if(res.status == 200){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Information has been unlocked!'
                    });
                    this.$emit('authd', res.data);
                }else if(res.status == 404){
                    this.errors = "We could not authenticate you. Please verify you clicked/pasted the correct URL.";
                }else{
                    this.errors = "The code you entered is not valid. Please try again.";
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.codeSent = false;
                this.authing = false;
            });
        },
        getNewCode(){ 
            this.redirectTo = null;
            this.authing = true;
            this.errors = null;
            let request = {
                endpoint: '/alerts/resend/' + this.$route.params.id, 
                method: 'post',
                data: {
                    last4: this.last4,
                    preview: this.user
                },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.codeSent = true;
                    this.requesting = false;
                    ++this.acKey;
                }else if(res.status == 200){
                    // last4 correct, but URL is wrong
                    this.codeSent = true;
                    this.requesting = false;
                    ++this.acKey;
                    this.$router.push('/alert/' + res.data.redirect);
                }else{
                    this.errors = "The number you entered is incorrect. Please try again.";
                    if(this.contact.phone.length){
                        this.errors += " Hint: The number ends with '" + this.contact.phone[11] + "'.";
                    }
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.authing = false;
            });
        },
        handleRedirect(){
            this.$router.push('/alert/' + this.redirectTo);
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>